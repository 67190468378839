import React, {useState, useEffect} from 'react';
import {HashRouter, Route, Switch, Redirect} from 'react-router-dom';

import {firebaseAuth} from './utils/firebase';
import './App.scss';

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./pages/Login/Login'));
const Register = React.lazy(() => import('./pages/Register/Register'));
const Page404 = React.lazy(() => import('./pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./pages/Page500/Page500'));

const Loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const PrivateRoute = ({render, ...otherProps}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(signedInUser => {
      setUser(signedInUser);
      setIsLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Route
      {...otherProps}
      render={props =>
        user ? (
          render(props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: props.location},
            }}
          />
        )
      }
    />
  );
};

const App = () => (
  <HashRouter>
    <React.Suspense fallback={Loading()}>
      <Switch>
        <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
        <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
        <PrivateRoute path="/" name="Home" render={props => <DefaultLayout {...props} />} />
      </Switch>
    </React.Suspense>
  </HashRouter>
);

export default App;
